import Chat from './transcription';
import Webcams from './webcam2';
import { Navbar, Nav, NavDropdown, Dropdown, Container, Row, Col, Card, Button, Stack, Spinner } from "react-bootstrap";
import './style.css';
import './interviewer.css';
import React, { useState, useEffect, useRef } from 'react';
import { FaUser, FaBriefcase } from 'react-icons/fa';
import {mongoose} from "mongoose";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useSearchParams, useNavigate } from 'react-router-dom';
import back_button from './back_button.png'
import {FormControl, FormLabel,FormControlLabel, FormGroup, RadioGroup,Radio, Box} from '@mui/material/';
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";

function Interviewer() {
  const [messageData, setMessageData] = useState(null);
  const [roomEntered, setRoomEntered] = useState(false);
  const [id, setID] = useState("")
  const [interviewstarted, setInterviewStarted] = useState(false);
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [timestampedText, setTimestampedText] = useState([]);
  const [assignmentFile, setAssignmentFile] = useState(null);
  const [assignmentText, setAssignmentText] = useState(null);
  const [studentID, setStudentID] = useState("");
  const [assignmentID, setAssignmentID] = useState("");
  const [email, setEmail] = useState("");
  const [aIDFromURL, setAIDFromURL] = useState(false);
  const [studentIdFromURL, setStudentIdFromURL] = useState(false);
  const txtStudentIDRef = useRef(null);
  const txtAssignnmentIDRef = useRef(null);
  const [timer, setTimer] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [src, setSrc] = useState(null);
  const [checked, setChecked] = useState(false);
  const [verifyRoomEntered, setVerifyRoomEntered] = useState(false);
  const [webcamDetected, setWebcamDetected] = useState(false);
  const [audioDetected, setAudioDetected] = useState(false);
  const [paragraphs, setParagraphs] = useState(null);

  const [recordingMetadata, setRecordingMetadata] = useState({"shouldRecord": false, "currQuestionRecording": "", "currQuestionAnswer" : ""});

  const [questions, setQuestions] = useState([]);


  let fileReader;
  const navigate = useNavigate();
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  useEffect(() => {
    setID(generateUniqueId());
    if (searchParams.get('assignment_id')) {
      setAssignmentID(searchParams.get('assignment_id'));
      setAIDFromURL(true);
    }
    if (searchParams.get('student_id')) {
      setStudentID(searchParams.get('student_id'));
      setStudentIdFromURL(true);
    }
  }, []);

  function generateUniqueId() {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base 36 string
    const randomNumber = Math.random().toString(36).substr(2, 5); // Generate random number and take a substring of the first 5 characters
    const uniqueId = `${timestamp}-${randomNumber}`; // Combine timestamp and random number with a hyphen
    return uniqueId;
  }

  const [selectedPrompt, setSelectedPrompt] = useState("");


  const handleMessageData = (data) => {
    setMessageData(data);
    console.log(data);
  };

  const handleStartRecording = () => {
    setRecording(true);
  };

  const handleStopRecording = () => {
    console.log("stopped recording")
    // setRecording(false);
  };

  const onVideoUrlChange = (url) => {
    setVideoUrl(url);
  };

  const handleInterviewStarterd = (state)=>{
    setInterviewStarted(state);
    console.log("interviewtoggled")
    if (state===false){
      setID(generateUniqueId())
      setRoomEntered(false)
    }
  }

  const handleBackButton = (e) => {
    if (assignmentFile) {
      setAssignmentFile(null);
      setParagraphs(null)
    } else{
      navigate(0);
    }
  }

  const handleStudentID = (event) => {
    setStudentID(event.target.value.trim());
  }

  const handleAssignmentID = (event) => {
    setAssignmentID(event.target.value.trim());
  }

  const handleEmail = (event) => {
    setEmail(event.target.value.trim());
  }

  const handleAgreementChange = (e) => {
    setChecked(e.target.value);
 }

  const handleEnterRoom = async () => {
    if (!checked) {
      alert("Please agree to the assignment declaration")
      return;
    }
    // console.log("enterring") 
    setUploadingFile(true);

    // const sID = txtStudentIDRef.current.value.trim();
    // setStudentID(sID);
    
    // const aID = txtAssignnmentIDRef.current.value.trim();
    // setAssignmentID(aID);

    const sID = studentID;
    const aID = assignmentID;

    // check whether access key is valid before proceeding
    const res = await fetch(process.env.REACT_APP_CHECK_ACCESS_KEY_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'student_id': sID, 'assignment_id': aID}),
    });
    const { valid } = await res.json();
    if (!valid) {
      alert("Invalid access key provided!");
      setUploadingFile(false);
      return;
    }

    // const a = await fetch('http://127.0.0.1:8443/api/generateVerificationQuestions', {
    // console.log(process.env.REACT_APP_API_KEY);

    if (!assignmentFile) {
      toast.error("No assignment file uploaded");
      setUploadingFile(false);
      return;
    }

    const a = await fetch(process.env.REACT_APP_FETCH_VERIF_QUESTIONS_URI, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({input: assignmentText, threshold:55, window:2,generate_qn:true})
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      response.json().then(data => {
        console.log(data["qn"]);
        setQuestions([{question: data["qn"], start: -1, end: -1, answer:""}]);
        setRoomEntered(true);
      });
    })
    .catch(error => {
      console.error('There was a problem with the network request:', error);
      toast.error("There was a problem with submitting your assignment. Please try again");
      setUploadingFile(false);
    });
  };


  const isPdf = (filename) => {
    return (filename.split('.').pop()).toLowerCase() == "pdf";
  }

  const isDocx = (filename) => {
    return (filename.split('.').pop()).toLowerCase() == "docx";
  }

  // extract text from a parseable pdf file
  const pdfToText = (data) => {
    console.assert( data  instanceof ArrayBuffer  || typeof data == 'string' );
    pdfjsLib.getDocument( data ).promise.then( function(pdf) {
      let complete = 0;
      var total = pdf.numPages;    
      var layers = {};        
      for (let i = 1; i <= total; i++){
        pdf.getPage(i).then( function(page){
        var n = page.pageNumber;
        page.getTextContent().then( function(textContent){
          console.log(textContent);
          if( null != textContent.items ){
            var page_text = "";
            var last_block = null;
            for( var k = 0; k < textContent.items.length; k++ ){
                var block = textContent.items[k];
                if( last_block != null && last_block.str[last_block.str.length-1] != ' '){
                    if( block.x < last_block.x )
                        page_text += "\r\n"; 
                    else if ( last_block.y != block.y && ( last_block.str.match(/^(\s?[a-zA-Z])$|^(.+\s[a-zA-Z])$/) == null ))
                        page_text += ' ';
                }
                page_text += block.str;
                last_block = block;
            }
            console.log(page_text);
            // textContent != null && console.log("page " + n + " finished."); //" content: \n" + page_text);
            console.log(textContent);
            layers[n] =  page_text + "\n\n";
          }
          complete++;
           if (complete == total){
            var full_text = "";
            var num_pages = Object.keys(layers).length;
            for( var j = 1; j <= num_pages; j++) {
              full_text += layers[j] ;
            }
            console.log(full_text);
            setAssignmentText(full_text);
           }
        }); // end  of page.getTextContent().then
        }); // end of page.then
      } // of for
    });
  }; // end of pdfToText()

  const handleFileRead = (e) => {
    const content = fileReader.result;
    setParagraphs(content);
    setAssignmentText(content);
  }
  const handlePDFFileRead = (e) => {
    const content = fileReader.result;
    pdfToText(content);
  }

  function str2xml(str) {
    if (str.charCodeAt(0) === 65279) {
      // BOM sequence
      str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
  }

  function getParagraphs(content) {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const paragraphsXml = xml.getElementsByTagName("w:p");
    const paragraphs = [];
  
    for (let i = 0, len = paragraphsXml.length; i < len; i++) {
      let fullText = "";
      const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
      for (let j = 0, len2 = textsXml.length; j < len2; j++) {
        const textXml = textsXml[j];
        if (textXml.childNodes) {
          fullText += textXml.childNodes[0].nodeValue;
        }
      }
      if (fullText) {
        paragraphs.push(fullText);
      }
    }
    return paragraphs;
  }

  const handleAssignmentUpload = (event) => {
    const file = event.target.files[0];
    // File size shouldn't be more than 5mb
    if (file.size > 5000000) {
      toast.error("File size too large. Please upload a file smaller than 5 mb");
      event.target.value = null;
      return;
    }
    setAssignmentFile(file);
    
    if (isPdf(file.name)) { // handle separately if pdf file as extra processing required
      setSrc(URL.createObjectURL(event.target.files[0]));
      fileReader = new FileReader();
      fileReader.onloadend = handlePDFFileRead;
      fileReader.readAsArrayBuffer(file);
    } else if (isDocx(file.name)) {
      fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const content = e.target.result;
        const paragraphs = getParagraphs(content);
        setParagraphs(paragraphs.join('\n'));
        setAssignmentText(paragraphs.join('\n'));
      };
      fileReader.onerror = (err) => console.error(err);
      fileReader.readAsBinaryString(file);
    } else {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    }

  };

  const handleVideoVerify = (event) => {
    setVerifyRoomEntered(true);
  }

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={checked}
        onChange={handleAgreementChange}
      >
        <FormControlLabel
        label="have used no AI help"
        control={<Radio />} value={1}
        />
        <FormControlLabel
          label="have used some AI help"
          control={<Radio />} value={2}
        />
      </RadioGroup>
      
    </Box>
  );


  return (
    <div>
      {roomEntered ? (
        <Container>
              <Card className='h-10'>
                <Card.Body>
                <Row md={{span: 1}} className="d-flex justify-content-center align-items-center">
                  <Col md={{span: 12}}>
                    {verifyRoomEntered && <Chat onmessage={handleMessageData} interId={id} timestampedText={timestampedText} questions={questions} setQuestions={setQuestions} recordingMetadata={recordingMetadata} setRecordingMetadata={setRecordingMetadata} timer={timer}/>}
                  </Col>
                  </Row>
                </Card.Body>
              </Card>

            
              {/* <Card className="h-100 w-100">
                <Card.Body>
                  
                </Card.Body>
              </Card> */}
            {/* </Col> */}
          <Row md={{span: 3}} className='d-flex justify-content-center align-items-center'>
            <Col md={{span: 5}}>
              {/* <Card> */}
                {/* <Card.Body> */}
                  <Webcams ID={id} setInterview={handleInterviewStarterd} setRecordingMetadata={setRecordingMetadata} questions={questions} setQuestions={setQuestions} sID={studentID} aID={assignmentID} email={email} docSnippet={assignmentText} docFile={assignmentFile} setTimer={setTimer} intvReady={verifyRoomEntered}/>
                {/* </Card.Body> */}
              {/* </Card> */}
            </Col>
              {!verifyRoomEntered && <div className="d-flex flex-column justify-content-center align-items-center"><Button className="verif_camera" onClick={handleVideoVerify}>Start</Button>
              <p>Do you look awesome here? If yes, please start.</p></div>}
          </Row>
        </Container>
      ) : (
        <div>
          <div>
            <Row>
            <div>
              <p className="heading">Authenticate your authorship & understanding</p>
              {assignmentFile && <img style={{ width: '80px', height: '60px' }} src={back_button} alt="Back" onClick={handleBackButton} />}
            </div>
            <div>
            {!assignmentFile && <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="form-group w-50 mb-4">
                {!studentIdFromURL && <label>Student ID</label>}
                {!studentIdFromURL && <input type="text" ref={txtStudentIDRef} id="sub-id-input" className="form-control" value={studentID}  onChange={handleStudentID} />}
                {!aIDFromURL && <label>Assignment ID</label>}
                {!aIDFromURL && <input type="text" ref={txtAssignnmentIDRef} className="form-control" value={assignmentID} onChange={handleAssignmentID} />}
                <label>Email (Optional. For receiving your results)</label>
                <input type="text" className="form-control" value={email} onChange={handleEmail} />
              </div>
              <h5>Upload any document below to verify your understanding of it.</h5>
              <div className="card w-50 mb-4">
                <div className="card-body">
                  <div className="file-area">
                    <input type="file" id="assignment-upload" name="assignment-upload" accept=".txt,.pdf,.docx" onChange={handleAssignmentUpload}/>
                      <div className="file-dummy">
                        <span className="success">{assignmentFile ? assignmentFile.name : "Please drag or upload a TXT, PDF or DOCX file here"}</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>}
            </div>
            <center>
            <div>
              {paragraphs ? (
                <div className="leftFlushBox">
                {paragraphs}
                </div>
              ) : assignmentFile ? (
                <embed src={src} height="500" width="100%"></embed>
              ) : null}
              {assignmentFile && (
                <p>{`After submission, you'll receive a content-related question. Provide a clear response showcasing your understanding of your submission`}</p>
              )}
            </div>
            </center>
            <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
            <FormControl sx={{ m: 3 }}>
              <FormLabel>I agree that this assignment was authored by me and I</FormLabel>
              <FormGroup>
                {children}
              </FormGroup>
            </FormControl>
            </div>
            {!uploadingFile && <Button className="upload_button" onClick={handleEnterRoom}>Authenticate</Button>}
            {uploadingFile && <Button className="upload_button" onClick={handleEnterRoom}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true">
              </Spinner>
              </Button>}
            </div>
            </Row>
          </div>
          <div>
          <ToastContainer />
          </div>
          
        </div>

      )}
    </div>
  );
}

export default Interviewer;
