import React from 'react';

function Home() {
  return (
    <div>
      <h1>Welcome to SeerInterviewer</h1>
      <p>We're excited to have you here!</p>
    </div>
  );
}

export default Home;
