import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import AWS from 'aws-sdk';
import { Form, Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { positions } from '@mui/system';
import { useNavigate } from "react-router-dom";
import { useWhisper } from '@chengsokdara/use-whisper'
import { ToastContainer, toast } from 'react-toastify';

import './webcam2.css'

const Webcams = ({ ID, setInterview, setRecordingMetadata, questions, setQuestions, sID, aID,email, docSnippet, docFile, setTimer, intvReady}) => {
  const webcamRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [recording, setRecording] = useState(false);
  const [uploadprogress, setUploadProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedVidDeviceId, setSelectedVidDeviceId] = useState(null);
  const [selectedAudDeviceId, setSelectedAudDeviceId] = useState(null);
  const [vidDevices, setvidDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [transcriptToSend, setTranscriptToSend] = useState("");
  const [inEditTranscript, setInEditTranscript] = useState(false);
  const [transcriptInProcessed, setTranscriptInProcessed] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [mic_stream, setMic_stream] = useState(new MediaStream());
  const [vidDevIndex, setVidDevIndex] = useState(0);
  const [audDevIndex, setAudDevIndex] = useState(0);
  const [loading, setLoading] = useState(false)

  let chunks = [];
  let audio_blobs_recorded = [];
  let timer = 0;

  
  const onDataAvailable = async (blob) => {
    audio_blobs_recorded.push(blob);
  }


  const { pauseRecording,startRecording,stopRecording,transcribing,transcript } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_API_TOKEN,
    removeSilence: true,
    // callback to handle transcription with custom server
    onDataAvailable
  })

  useEffect(() => {
    getDevices();
    if(intvReady){
      startMediaRecording();
    }
  }, [intvReady]);

  useEffect(() => {
    if(transcript.text){
      setTranscriptInProcessed(false)
      setTranscriptToSend(transcript.text)
      setInEditTranscript(true)
      setLoading(false);
    }
  }, [transcript]);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (transcriptInProcessed) {
        setLoading(false);
        startRecording();
        toast.error("There was a problem with processing your speech. Please try again");
      }
    }, 15000);

    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout);
    };
  }, [transcriptInProcessed]);


  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/home`; 
    // navigate(path, { replace: true });
    navigate(0);
  }

  const getDevices = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({audio: true, video: true});   
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      const audDevices = devices.filter(device => device.kind == 'audioinput');
      setMic_stream(await navigator.mediaDevices.getUserMedia({ video: false, audio: {deviceId: selectedAudDeviceId} }));
      // console.log(mic_stream);
      setvidDevices(videoDevices);
      setAudioDevices(audDevices);
      setSelectedVidDeviceId(videoDevices[0].deviceId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVidMediaChange = (event) => {
    setVidDevIndex(event.target.value);
    setSelectedVidDeviceId(vidDevices[event.target.value].deviceId);
  }

  const handleAudMediaChange = async (event) => {
    setAudDevIndex(event.target.value);
    setSelectedAudDeviceId(audioDevices[event.target.value].deviceId);
    setMic_stream(await navigator.mediaDevices.getUserMedia({ video: false, audio: {deviceId: audioDevices[event.target.value].deviceId} }));
  }

  const createSelectItems = (devs) => {
    let items = [];
    for (let i = 0; i < devs.length; i++) {             
        items.push(<option value={i}>{devs[i].label}</option>);
    }
    return items;
  }

  

  const sendData = (recording, audio_recording) => {
    let data = new FormData();

    // add stop time to last question
    const tmpQns = [...questions];
    tmpQns[tmpQns.length-1].end = timer;

    const formattedQns = tmpQns.map((qn) => {
      if (Array.isArray(qn.question))
        qn.question = qn.question.join(' ');
      return qn;
    })

    // upload the video and audio data to the backend along with user responses
    data.append('videoFile', recording);
    data.append('audioFile', audio_recording);
    data.append('documentFile', docFile);
    data.append('questions', JSON.stringify(formattedQns));
    data.append('student_id', sID);
    data.append('assignment_id', aID);
    data.append('email', email)
    data.append('doc_snippets', JSON.stringify(docSnippet));
    let response = fetch(process.env.REACT_APP_UPLOAD_CAPTCHA_URI, {
    // let response = fetch('http://localhost:5678/api/sincereapi/upload', {
      method: 'POST',
      body: data
    });
    console.log("called aPi");
    console.log(...data);
    confirmedUpload();

  };

  let videoOptions = {mimeType: 'video/webm'};
  let videoFileType = {type: 'video/webm'};
  let videoExt = "webm"
  if (MediaRecorder.isTypeSupported('video/webm')) {
    videoOptions = {mimeType: 'video/webm'};
    videoFileType = {type: 'video/webm'};
  } else if (MediaRecorder.isTypeSupported('video/mp4')) {
    videoOptions = {mimeType: 'video/mp4', videoBitsPerSecond : 100000};
    videoFileType = {type: 'video/mp4'};
    videoExt = "mp4"
  } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')){
    videoOptions = {mimeType: 'video/webm; codecs=vp9'};
    videoFileType = {type: 'video/webm'};
  }

  let audioFileType = {type: 'audio/mp3'}
  let audioExt = "mp3"

  const startMediaRecording = () => {
    setRecordingMetadata({"shouldRecord": true, "currQuestionRecording": "", "currQuestionAnswer" : ""});
    setInterview(true);
    const webcamStream = webcamRef.current?.stream;
    if (!webcamStream) {
      console.log('Webcam stream not available');
      return;
    }

    setInterval(() => { 
      timer += 0.1;
      setTimer(timer);
    }, 100);

    const mediaRecorder = new MediaRecorder(webcamStream, videoOptions);
    startRecording();
    let frameCounter = 0;
    mediaRecorder.addEventListener('dataavailable', event => {
      frameCounter++;
      if (frameCounter >0) {
        console.log("frame sent");
        // Reset the frame count
        frameCounter = 0;
      }
    });
    mediaRecorder.ondataavailable = (e) => {
      // console.log("bruh")
      chunks.push(e.data);
    };
  
    mediaRecorder.start();
    setRecording(true);
    console.log('Started recording');
  };


  const submitTranscript = async() => {
    setInEditTranscript(false);
    setLoading(true);
    
    const a = await fetch(process.env.REACT_APP_FETCH_PROCESS_INIT_QUESTIONS_URI, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({input: docSnippet, question:questions[questionIndex].question, answer:transcriptToSend})
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      response.json().then(async data => {
        console.log(questionIndex)
        const newQnsArray = [...questions];
        newQnsArray[questionIndex].answer = transcriptToSend;
        newQnsArray[questionIndex].assessment = data["assessment"];
        newQnsArray[questionIndex].classification = data["classification"];
        setQuestions(newQnsArray);
        // Use a regular expression with negative lookahead to match "SHOW UNDERSTANDING"
        // but not when preceded by "NOT"
        const regex = /(?<!\bNOT\s)SHOW UNDERSTANDING/g;

        // Check if the string contains the pattern
        const containsShowUnderstanding = regex.test(data["classification"]);
        if(containsShowUnderstanding || questionIndex == 2){
          stopMediaRecording();
          let recording = new File(chunks, `recording.${videoExt}`, videoFileType);
          let audio_recording = new File(audio_blobs_recorded, `a_recording.${audioExt}`, audioFileType);
          sendData(recording, audio_recording);
          setLoading(false);
        }
        else if(!containsShowUnderstanding){
          let questionData = await generateFurtherQn(data["assessment"])
          const additionalQnsArray = [...newQnsArray];
          additionalQnsArray.push({question: questionData, start: -1, end: -1, answer:""})
          setQuestions(additionalQnsArray);
          setQuestionIndex(questionIndex+1)
          toast.success('Please answer the next question');
          startRecording();
          setLoading(false);
        }
      });
    })
    .catch(error => {
      console.error('There was a problem with the network request:', error);
      toast.error("There was a problem with submitting your assignment. Please refresh and try again");
      setLoading(false);
    });
  }

  const generateFurtherQn = async(assessment) => {
    const a = await fetch(process.env.REACT_APP_FETCH_PROCESS_SUBSEQUENT_QUESTIONS_URI, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({input: docSnippet, question:questions[questionIndex].question, answer:transcriptToSend, assessment:assessment})
    }).then(async response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data["output"]
    })
    .catch(error => {
      console.error('There was a problem with the network request:', error);
      toast.error("There was a problem with submitting your assignment. Please refresh and try again");
    });
    return a;
  }

  const waitForProcessing = async() => {
    setTranscriptInProcessed(false)
    setTranscriptInProcessed(true)
    stopRecording();
    setLoading(true);
  }

  const confirmedUpload = () => {
    let messageText = 'Your captcha for ' + aID + " has been submitted successfully on " + new Date() + ". "
    if(email){
      messageText += "The result will be send to your email at " + email + " within the next 15 minutes."
    }
    confirmAlert({
      title: 'Response Submitted',
      message: messageText,
      buttons: [
        {
          label: 'Ok',
          onClick: () => routeChange()//console.log("exiting")
        }
      ]
    });
  };

  const stopMediaRecording = () => {
    if (webcamRef.current?.stream) {
      webcamRef.current.stream.getTracks().forEach(track => track.stop());
    } 
    console.log(chunks);
    console.log(audio_blobs_recorded);

    chunks = [];
    audio_blobs_recorded = [];
    transcript.text = "";
    setRecording(false);
  };




  return (
    <div>
      {/* style={{ width: '150%', height: '40%'}} */}

      {!inEditTranscript && (<Webcam className='videoFrame' audio={true} muted={true} ref={webcamRef} videoConstraints={{ deviceId: selectedVidDeviceId}} />)}
      {inEditTranscript && (
        <Form>
          <h5>Adjust your answers before submitting</h5>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Transcription:</Form.Label>
            <Form.Control as="textarea" value={transcriptToSend} rows={5} onChange={e => setTranscriptToSend(e.target.value)}/>
          </Form.Group>
        </Form>
      )}
      <Modal show={showModal} onHide={() => {}}>
        <Modal.Header closeButton>
          <Modal.Title>Uploading video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar now={uploadprogress} label={`${uploadprogress}%`} />
        </Modal.Body>
      </Modal>
      {!recording && !videoUrl && (
        <div className="justify-content-center align-items-center">
          {intvReady && <Button style={{width: '100%'}} className="btn btn-primary" onClick={startMediaRecording}>Start Interview</Button>}
          <div style={{ marginTop: '0.3rem'}} className=" justify-content-center align-items-center d-flex flex-column">
            <select onChange={handleVidMediaChange}>
              {createSelectItems(vidDevices)}
            </select>
            <div style={{ marginTop: '0.3rem'}}>
            <select onChange={handleAudMediaChange}>
              {createSelectItems(audioDevices)}
            </select>
            </div>
          </div>
        </div>
      )}
      {!inEditTranscript && recording && (
        <Button style={{width: '100%'}} className="btn btn-danger" onClick={waitForProcessing}>Process Answer</Button>
      )}
      {inEditTranscript && (
        <Button style={{width: '100%'}} className="btn btn-danger" onClick={submitTranscript}>Submit Answer</Button>
      )}
      {videoUrl && (
        <video src={videoUrl} controls muted />
      )}
      {loading&& (
        <div style={{width:'100vw',height:'100vh',background:'#000000ad',position:'fixed', textAlign:'center', bottom:'0',right:'0'}}> 
          <Spinner
              as="span"
              animation="border"
              variant="light"
              size="lg"
              role="status"
              aria-hidden="true"
              style={{margin: '0px', position: 'absolute',top: '50%'}}
              >
            </Spinner>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Webcams;