import React, { useEffect, useState, useCallback } from 'react';
import './Chat.css';
import { Collapse, TextareaAutosize, Button } from '@mui/material';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


function Message({ text, isSent }) {
  return (
    <div className={`message ${isSent ? 'sent' : 'received'}`}>
      {text}
    </div>
  );
}

function Chat({ onmessage, interId, timestampedText, username, questions, setQuestions, recordingMetadata, setRecordingMetadata, timer}) {
  const [message, setMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [newaudio, setNewaudio] = useState(false);
  const [messageChunks, setMessageChunks] = useState([]);
  const [currQuestion, setCurrQuestion] = useState('');
  const [editingText, setEditingText] = useState(false);
  const [nextQuestion, setNextQuestion] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [open, setOpen] = React.useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
 
  const handleClickToOpen = () => {
      setOpen(true);
  };
 
  const handleToClose = () => {
    onmessage(nextQuestion);
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleMicClick = () => {
    if (!recordingMetadata["shouldRecord"]) {
      setRecordingMetadata({"shouldRecord": true, "currQuestionRecording": recordingMetadata["currQuestionAnswering"], "currQuestionAnswer" : recordingMetadata["currQuestionAnswer"]});
    }
    setMessage(message + " " + transcript);
    SpeechRecognition.startListening();
  };


  const handleSendClick = () => {
    setMessage(message + " " + transcript);

    setMessageChunks(prevChunks => [
      ...prevChunks,
      { text: message, isSent: true }
    ]);
    

    resetTranscript(); // resetting what is available

    setMessage('');
    if (recordingMetadata["shouldRecord"]) {
      setQuestionIndex(questionIndex + 1);
    }
  };

  const handlePrevQClick = () => {
    setQuestionIndex(questionIndex - 1);
  }

  const handleTextToggle = () => {
    setMessage(message + " " + transcript);
    resetTranscript();
    setEditingText(!editingText);
  }


  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    let interval = null;
    var index = 0;
    var newText = "";
  
    const displayWordsWithDelay = () => {
      if (index <= timestampedText.length) {
        if (timestampedText[index] == null) {
          clearInterval(interval);
          return;
        } else {
          newText = newText + " " + timestampedText[index]["text"];
          setCurrQuestion(newText);
          index += 1;
        }
      } else {
        clearInterval(interval);
      }
    };
    interval = setInterval(displayWordsWithDelay, 130);
  
    return () => clearInterval(interval);
  }, [timestampedText]);

  useEffect(() => {
    console.log('qnIndex changed');
    const tmpQns = [...questions];

    if (questionIndex === 0)
      tmpQns[questionIndex].start = 0;

    if (questionIndex > 0)
      tmpQns[questionIndex-1].end = timer;
    if (questionIndex < tmpQns.length)
      tmpQns[questionIndex].start = timer;

    console.log(tmpQns);

    setQuestions(tmpQns);
  }, [questionIndex]);

  useEffect(() => {
    if(questions.length != questionIndex + 1){
      handleSendClick();
    }
  }, [questions]);

  return (
    <div>
      <div className="chat-messages">
        <Message text={questions[questionIndex].question} isSent={false} />
      </div>
      {/* <div>
            <Message text={message + " " + transcript} isSent={false} />
            {0 != questionIndex && <Button onClick={handlePrevQClick}>Prev Question</Button>}
            {questions.length != questionIndex + 1 && <Button onClick={handleSendClick}>Next Question</Button>}
      </div> */}
    </div>
  );
}

export default Chat;