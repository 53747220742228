import React, { Component } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import Builder from './builder';
import Interviewer from './interviewer';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Card } from "react-bootstrap";
import Home from './Home';
// import SummaryList from './summary';
import './App.css'

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">Sincerely<sub style={{fontSize:".55em", color:"red"}}>&beta;eta</sub></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Container>
                <Routes>
                  <Route path="/" element={<Interviewer/>} />
                </Routes>
          </Container>
        </div>
      </Router>
    );
  }
}

export default App;
